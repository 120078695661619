import { Components } from '@mui/material'

export const TypographyOverrides = (): Components['MuiTypography'] => ({
  defaultProps: {},
  styleOverrides: {
    root: {
      // '> *': {
      //   marginTop: 0,
      //   marginBottom: 0,
      // },
    },
  },
})
