import React from 'react'

import { styled, Container, Grid } from '@mui/material'
import MetaNavigation from '../metaNavigation'
import SocialNavigation from '../socialNavigation'
import Link from '@components/core/link'

import { missingOptionalPropsCheck } from '@system/utils/missingOptionalPropsCheck'
type NavItems = {
  titleNavigation?: string
  fields: BASE.Contentful.IFields
  contentful_id: string
}

export type FooterProps = BASE.Helpers.IReactDefaultProps & {
  navigationItems: NavItems[]
  metaNavigationItems: NavItems[]
}

const Root = styled('footer')(({ theme }) => ({
  padding: '60px 0 52px 0',
  backgroundColor: theme.palette.common.white,

  [theme.breakpoints.up('lg')]: {
    padding: '36px 0 52px 0',
  },

  a: {
    fontSize: '14px',
  },
}))

const Navigation = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  listStyle: 'none',
  display: 'flex',
  gap: theme.spacing(6),
  flexWrap: 'wrap',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(6),
  },
}))

export default function Footer({
  navigationItems,
  metaNavigationItems,
}: FooterProps) {
  return (
    <Root>
      <Container>
        <Grid container>
          <Grid item xs={6} md={8}>
            <Navigation>
              {navigationItems.map((navigationItem) => {
                missingOptionalPropsCheck(
                  { titleNavigation: navigationItem.titleNavigation },
                  'Modules | Global | Footer',
                  navigationItem.contentful_id
                )
                return (
                  <li key={navigationItem.fields.fullPath}>
                    {navigationItem.titleNavigation && (
                      <Link
                        to={navigationItem.fields.fullPath}
                        isExternal={navigationItem.fields.isExternal}
                      >
                        {navigationItem.titleNavigation}
                      </Link>
                    )}
                  </li>
                )
              })}
            </Navigation>
          </Grid>
          <Grid item xs={6} md={4} sx={{ textAlign: 'right' }}>
            <SocialNavigation />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '32px' }}>
            <MetaNavigation
              navigationItems={metaNavigationItems}
              color="black"
              horizontal
            />
          </Grid>
        </Grid>
      </Container>
    </Root>
  )
}
