import { Components, Theme } from '@mui/material'

export const ButtonOverrides = (theme: Theme): Components['MuiButton'] => ({
  defaultProps: {
    disableElevation: true,
    TouchRippleProps: {
      classes: {
        root: 'MuiButton-touchRipple',
        child: 'MuiButton-touchRippleChild',
        rippleVisible: 'MuiButton-touchRippleVisible',
      },
    },
  },
  styleOverrides: {
    root: {
      font: 'inherit',
      borderRadius: theme.spacing(6),
      letterSpacing: 0,
      [theme.breakpoints.up('lg')]: {
        borderRadius: theme.spacing(8),
      },
      textTransform: 'none',
    },
    outlined: {
      color: theme.palette.grey[300],
      fontSize: 12,
      lineHeight: 1.3334,
      fontWeight: 500,
      borderColor: theme.palette.grey[300],
      backgroundColor: 'transparent',
      padding: '3px 8px',
      [theme.breakpoints.up('lg')]: {
        '&:hover': {
          color: theme.palette.grey[900],
          borderColor: theme.palette.grey[900],
          backgroundColor: 'transparent',
        },
      },
    },
    containedPrimary: {
      lineHeight: 1.5,
      color: theme.palette.common.white,
      minWidth: '160px',
      minHeight: '48px',
      padding: theme.spacing(0, 6),
      fontSize: 14,
      fontWeight: 600,
      [theme.breakpoints.up('lg')]: {
        minWidth: '200px',
        minHeight: '56px',
        padding: theme.spacing(0, 8),
        fontSize: 16,
        '&:not(.Mui-disabled):hover': {
          transition: 'all .2s ease-in-out 0s',
          opacity: 0.6,
        },
        '&:not(.Mui-disabled):focus': {
          boxShadow: `0px 0px 0px 3px ${theme.palette.grey[300]}`,
        },
      },
      '&.Mui-disabled': {
        border: 'none',
        color: theme.palette.grey[300],
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
})
