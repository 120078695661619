import React, { useState } from 'react'

export interface ILangSwitchContext {
  langSwitchLink: string | undefined
  setLangSwitchLink: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const LangSwitchContext = React.createContext<ILangSwitchContext>({
  langSwitchLink: undefined,
  setLangSwitchLink: () => undefined,
})

export type LangSwitchProviderProps = {
  children: React.ReactNode
}

export default function LangSwitchProvider({
  children,
}: LangSwitchProviderProps): React.ReactElement {
  const [langSwitchLink, setLangSwitchLink] = useState<string>()

  return (
    <LangSwitchContext.Provider value={{ langSwitchLink, setLangSwitchLink }}>
      {children}
    </LangSwitchContext.Provider>
  )
}
