import React, { useEffect, useState, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'

import { Box, CssBaseline, Theme, styled } from '@mui/material'

import Header, { HEADER_HEIGHT } from '@components/modules/global/header'
import Footer from '@components/modules/global/footer'
import DefaultTriangle from '@static/img/icons/triangle.svg'

export type LayoutProps = BASE.Helpers.IReactDefaultProps & {
  pageContext: BASE.Helpers.IPageContext
}

const Root = styled('div')({
  position: 'relative',
  overflow: 'hidden',
})

const Triangle = styled(DefaultTriangle)(() => ({
  position: 'absolute',
  height: 'auto',
  zIndex: -1,
}))

const navigationMainGraphQL = graphql`
  query {
    navigationMain: contentfulF1Navigation(
      identifier: { eq: "navigationMain" }
    ) {
      node_locale
      pages {
        ... on ContentfulP1Generic {
          ...NavigationFragment
          subPages {
            ... on ContentfulP2Article {
              ...NavigationFragment
            }
            ... on ContentfulP3External {
              ...NavigationFragment
            }
            ... on ContentfulP1Generic {
              ...NavigationFragment
              subPages {
                ... on ContentfulP1Generic {
                  ...NavigationFragment
                }
                ... on ContentfulP2Article {
                  ...NavigationFragment
                }
                ... on ContentfulP3External {
                  ...NavigationFragment
                }
              }
            }
          }
        }
        ... on ContentfulP2Article {
          ...NavigationFragment
        }
        ... on ContentfulP3External {
          ...NavigationFragment
        }
      }
    }
    navigationMeta: contentfulF1Navigation(
      identifier: { eq: "navigationMeta" }
    ) {
      node_locale
      pages {
        __typename
        ... on ContentfulP1Generic {
          ...NavigationFragment
        }
        ... on ContentfulP3External {
          ...NavigationFragment
        }
        ... on ContentfulP2Article {
          ...NavigationFragment
        }
      }
    }
    navigationFooter: contentfulF1Navigation(
      identifier: { eq: "navigationFooter" }
    ) {
      node_locale
      pages {
        __typename
        ... on ContentfulP1Generic {
          ...NavigationFragment
        }
        ... on ContentfulP3External {
          ...NavigationFragment
        }
        ... on ContentfulP2Article {
          ...NavigationFragment
        }
      }
    }
  }
`

export default function Layout({ children, pageContext }: LayoutProps) {
  const location = useLocation()
  const mainRef = useRef<HTMLDivElement>(null)
  const { navigationMain, navigationFooter, navigationMeta } = useStaticQuery(
    navigationMainGraphQL
  )
  const [isTopPosition, setIsTopPosition] = useState(true)
  const [hasStage, setHasStage] = useState(false)

  useEffect(() => {
    if (mainRef.current) {
      const stages = mainRef.current.querySelectorAll('[data-stage]')
      setHasStage(stages.length > 0)
    }
  }, [location.pathname])

  useEffect(() => {
    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      setIsTopPosition(scrollY < HEADER_HEIGHT.L)
    }

    const onScroll = () => {
      window.requestAnimationFrame(updateScrollDir)
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [isTopPosition])

  const articleBackgroundTriangleColor =
    pageContext.page?.backgroundTriangleColor || null
  const isDontDriveHighPage =
    pageContext.contentful_id === '1CSeJ3LRyGPuKjYPqZX7Ge'
  return (
    <Root data-root>
      <CssBaseline />
      <Header
        navigationItems={navigationMain?.pages}
        metaNavigationItems={navigationMeta?.pages}
        small={isTopPosition}
        customHeadlineColour={isDontDriveHighPage ? 'white' : 'black'}
      />
      <Box
        ref={mainRef}
        component="main"
        sx={(muiTheme: Theme) => ({
          marginTop: `${HEADER_HEIGHT.S}px`,
          [muiTheme.breakpoints.up('lg')]: {
            marginTop: `${HEADER_HEIGHT.L}px`,
          },
        })}
      >
        {children}
      </Box>

      {isDontDriveHighPage ? null : hasStage ? (
        <Triangle
          sx={(theme) => ({
            color: !!articleBackgroundTriangleColor
              ? articleBackgroundTriangleColor
              : theme.palette.common['green'],
            top: { xs: '4300px', lg: '4000px' },
            left: '50%',
            width: { xs: '880px', lg: '1300px' },
            transform: 'translate(-95%, 0%) rotate(0deg)',
            '> path': {
              fill: !!articleBackgroundTriangleColor
                ? articleBackgroundTriangleColor
                : theme.palette.common[theme.darkTheme ? 'turquoise' : 'green'],
            },
          })}
        />
      ) : (
        <>
          <Triangle
            sx={(theme) => ({
              color: !!articleBackgroundTriangleColor
                ? articleBackgroundTriangleColor
                : theme.palette.common['green'],
              top: '0',
              left: '50%',
              width: { xs: '630px', lg: '2160px' },
              transform: 'translate(-8%, -10%) rotate(0deg)',
              '> path': {
                fill: !!articleBackgroundTriangleColor
                  ? articleBackgroundTriangleColor
                  : theme.palette.common[
                      theme.darkTheme ? 'turquoise' : 'green'
                    ],
              },
            })}
          />
          <Triangle
            sx={(theme) => ({
              color: !!articleBackgroundTriangleColor
                ? articleBackgroundTriangleColor
                : theme.palette.common['cyan'],
              top: { xs: '2100px', lg: '4000px' },
              left: '50%',
              width: { xs: '1000px', lg: '2160px' },
              transform: 'translate(-77%, 0%) rotate(0deg)',
              '> path': {
                fill: !!articleBackgroundTriangleColor
                  ? articleBackgroundTriangleColor
                  : theme.palette.common['cyan'],
              },
            })}
          />
          <Triangle
            sx={(theme) => ({
              color: !!articleBackgroundTriangleColor
                ? articleBackgroundTriangleColor
                : theme.palette.common['pink'],
              top: { xs: '6500px', lg: '7000px' },
              left: '50%',
              width: { xs: '590px', lg: '1290px' },
              transform: {
                xs: 'translate(-10%, 0%) rotate(0deg)',
                lg: 'translate(15%, 0%) rotate(0deg)',
              },
              '> path': {
                fill: !!articleBackgroundTriangleColor
                  ? articleBackgroundTriangleColor
                  : theme.palette.common['pink'],
              },
            })}
          />
        </>
      )}

      <Footer
        navigationItems={navigationFooter?.pages}
        metaNavigationItems={navigationMeta?.pages}
      />
    </Root>
  )
}
