import { FontSizeType } from '@mui/material/styles/createTheme'

const FontSize: FontSizeType = new Map([
  ['xs', '14px'],
  ['sm', '16px'],
  ['base', '18px'],
  ['lg', '18px'],
  ['2lg', '20px'],
  ['3lg', '24px'],
  ['xl', '28px'],
  ['2xl', '36px'],
  ['3xl', '48px'],
  ['4xl', '64px'],
  ['5xl', '128px'],
])

export default FontSize
