import {
  createTheme,
  Mixins as MixinsType,
  Palette,
} from '@mui/material/styles'

import { TypographyOverrides } from './overrides/typography'
import { PaletteOverrides } from './overrides/palette'
import { ComponentOverrides } from './overrides/components'
import Spacing from './definitions/spacing'
import Breakpoints from './definitions/breakpoints'
import Mixins from './mixins'
import FontSize from './definitions/fontSize'
import { BoxShadows } from './definitions/boxShadows'
import { Typography } from '@mui/material/styles/createTypography'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true
  }
}

const theme = createTheme({
  spacing: Spacing.unit,
  breakpoints: Breakpoints,
  maxWidth: Spacing.maxWidth,
  fontSize: FontSize,
  gutter: (breakpoint) => Spacing.gutters[breakpoint],
  boxShadows: BoxShadows,
  darkTheme: false,
})

// Palette Overrides
theme.palette = {
  ...theme.palette,
  ...(PaletteOverrides(theme) as Palette),
}
// Typography Overrides
theme.typography = {
  ...theme.typography,
  ...(TypographyOverrides(theme) as Typography),
}

theme.shadows = {
  ...theme.shadows,
}

theme.mixins = Mixins(theme) as MixinsType
// Component Overrides
theme.components = ComponentOverrides(theme)

export default theme
