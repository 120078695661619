import React, { ReactNode, ReactElement } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { defaultLang } from '@system/translations'

export interface IGlobalTextContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getText: (key: string, searchValue?: string, newValue?: string) => any
}

export const GlobalTextContext = React.createContext<IGlobalTextContext>({
  getText: () => {
    return null
  },
})

export type GlobalTextProviderProps = {
  children: ReactNode
  pageContext: BASE.Helpers.IPageContext
}

export default function GlobalTextProvider({
  children,
  pageContext,
}: GlobalTextProviderProps): ReactElement {
  const { globalText } = useStaticQuery(graphql`
    query {
      globalText: allContentfulGlobalText {
        nodes {
          node_locale
          key
          value
        }
      }
    }
  `)

  const globalTexts: Record<string, string> = Object.assign(
    {},
    ...globalText?.nodes
      .filter(
        (node: BASE.Helpers.IGlobalText) =>
          node.node_locale == (pageContext.locale || defaultLang)
      )
      .map((node: BASE.Helpers.IGlobalText) => ({
        [node.key]: node.value,
      }))
  )

  function getText(
    key: string,
    searchValue?: string,
    newValue?: string
  ): string {
    if (searchValue && newValue) {
      return globalTexts[key].replace(`{{${searchValue}}}`, newValue)
    }
    return globalTexts[key]
  }

  return (
    <GlobalTextContext.Provider value={{ getText }}>
      {children}
    </GlobalTextContext.Provider>
  )
}
