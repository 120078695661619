const unit = 4
const gutters = {
  xs: 6,
  sm: 6,
  md: 8,
  lg: 8,
  xl: 8,
  xxl: 8,
}

const Spacing = {
  unit,
  gutters,
  maxWidth: {
    md: 768,
    lg: 1200,
    xl: 1920,
    xxl: 2540,
  },
}

export default Spacing
