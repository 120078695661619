import { Components, Theme } from '@mui/material'

export const MuiFormControlOverrides = (): Components['MuiFormControl'] => ({
  styleOverrides: {
    root: {
      width: '100%',
      '& .MuiInputBase-root:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        borderColor: `#000`,
      },

      '& .MuiInputBase-root:after': {
        borderColor: `#000 !important`,
      },
      '& .MuiInputBase-root:before': {
        borderBottomColor: `#000 !important`,
      },
    },
  },
})

export const MuiFormHelperTextOverrides = (
  theme: Theme
): Components['MuiFormHelperText'] => ({
  styleOverrides: {
    root: {
      marginLeft: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingTop: theme.spacing(1),
      '&.Mui-error': {
        color: theme.palette.common.red,
      },
    },
  },
})

export const MuiInputLabelOverrides = (
  theme: Theme
): Components['MuiInputLabel'] => ({
  styleOverrides: {
    root: {
      color: theme.palette.common.black,
      fontSize: '16px',
      fontWeight: '400',
      marginBottom: theme.spacing(3),
      '&.Mui-focused, &.MuiInputLabel-shrink': {
        color: theme.palette.common.black,
      },
      '&.Mui-error': {
        color: theme.palette.common.red,
      },
    },
  },
})
