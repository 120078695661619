import { BreakpointsOptions } from '@mui/material'

const Breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1921,
  },
}

export default Breakpoints
