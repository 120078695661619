import { Components, Theme } from '@mui/material'

import {
  DINProRegular,
  DINProBold,
  DINProBlack,
} from '../../definitions/fontFamily'

export const CssBaselineOverrides = (
  theme: Theme
): Components['MuiCssBaseline'] => ({
  styleOverrides: {
    '@font-face': [DINProRegular],
    fallBacks: [
      {
        '@font-face': DINProBold,
      },
      {
        '@font-face': DINProBlack,
      },
    ],
    a: {
      color: 'currentColor',
    },
    strong: {
      fontFamily: theme.typography.fontFamilyBold,
    },
    b: {
      fontFamily: theme.typography.fontFamilyBold,
    },
    button: {
      background: 'none',
      border: 'none',
      padding: 0,
      font: 'inherit',
      cursor: 'pointer',
      outline: 'inherit',
    },
  },
})
