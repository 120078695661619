import React, { PropsWithChildren, ReactElement } from 'react'
import { styled } from '@mui/material'
import useGlobalText from '@system/hooks/useGlobalText'

import FacebookLogo from '@static/img/icons/facebook.svg'
import InstagramLogo from '@static/img/icons/instagram.svg'
import YouTubeLogo from '@static/img/icons/youtube.svg'

const Root = styled('ul')(({ theme }) => ({
  margin: 0,
  padding: 0,
  listStyle: 'none',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(4),
}))

export default function Header(): ReactElement {
  const { getText } = useGlobalText()

  function ListElement({
    href,
    children,
  }: { href: string } & PropsWithChildren) {
    return (
      <li>
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      </li>
    )
  }

  return (
    <Root>
      {getText('instagramUrl') && (
        <ListElement href={getText('instagramUrl')}>
          <InstagramLogo />
        </ListElement>
      )}
      {getText('facebookUrl') && (
        <ListElement href={getText('facebookUrl')}>
          <FacebookLogo />
        </ListElement>
      )}
      {getText('youtubeUrl') && (
        <ListElement href={getText('youtubeUrl')}>
          <YouTubeLogo />
        </ListElement>
      )}
    </Root>
  )
}
