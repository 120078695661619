import { Theme } from '@mui/material/'
import {
  ColorPartial,
  // Palette,
  PaletteOptions,
} from '@mui/material/styles/createPalette'

const Colors: Record<string, ColorPartial | string> = {
  blue: '#0000FF',
  violet: '#8024EF',
  pink: '#F2A5FA',
  orange: '#F3C03B',
  yellow: '#FFF000',
  cyan: '#00FFFF',
  darkgreen: '#002300',
  lightgreen: '#63C93D',
  turquoise: '#00E9B8',
  green: '#00FF00',
  red: '#FF0000',
  grey: {
    200: '#e5e5e5',
    100: '#fafafa',
  },
  black: '#000000',
  white: '#ffffff',
}
export const PaletteOverrides = (theme: Theme): PaletteOptions => ({
  ...theme.palette,
  common: Colors,
})
