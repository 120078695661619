import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import Audio from '@static/img/icons/icon_audio.svg'
import ChevronDown from '@static/img/icons/icon_chevron_down.svg'
import Close from '@static/img/icons/icon_close.svg'
import Down from '@static/img/icons/icon_down.svg'
import Left from '@static/img/icons/icon_left.svg'
import LeichteSprache from '@static/img/icons/icon_leicht_sprache.svg'
import ReadingTime from '@static/img/icons/icon_lesedauer.svg'
import Minus from '@static/img/icons/icon_minus.svg'
import PartnerLogin from '@static/img/icons/icon_partner_login.svg'
import Plus from '@static/img/icons/icon_plus.svg'
import Quote from '@static/img/icons/quote.svg'
import Right from '@static/img/icons/icon_right.svg'
import Right1 from '@static/img/icons/icon_right1.svg'
import Share from '@static/img/icons/icon_share.svg'
import Up from '@static/img/icons/icon_up.svg'
import QuoteAttention from '@static/img/icons/quote_attention.svg'
import QuoteChat from '@static/img/icons/quote_chat.svg'
import QuoteEye from '@static/img/icons/quote_eye.svg'
import QuoteFingers from '@static/img/icons/quote_fingers.svg'
import QuoteQuote from '@static/img/icons/quote_quote.svg'
import Mail from '@static/img/icons/icon_mail.svg'
import PDF from '@static/img/icons/picture_as_pdf.svg'
import ScrollDown from '@static/img/icons/icon_scroll_down.svg'
import Menue from '@static/img/icons/icon_menue.svg'
import QuoteCannabisAlert from '@static/img/dontdrivehigh/Icon_Quote.svg'
import QuoteCannabisCorner from '@static/img/dontdrivehigh/Isolationsmodus.svg'
import StageDontDriveHigh from '@static/img/dontdrivehigh/Stage.svg'

export const IconSet = {
  Up,
  Share,
  Right1,
  Right,
  Plus,
  PartnerLogin,
  Minus,
  ReadingTime,
  LeichteSprache,
  Left,
  Down,
  Close,
  ChevronDown,
  Audio,
  Quote,
  QuoteAttention,
  QuoteChat,
  QuoteEye,
  QuoteFingers,
  QuoteQuote,
  QuoteCannabisAlert,
  QuoteCannabisCorner,
  Mail,
  PDF,
  ScrollDown,
  Menue,
  StageDontDriveHigh,
}

export type IconProps = BASE.Helpers.IReactDefaultProps & {
  name: keyof typeof IconSet
  fontSize?: 'inherit' | 'small' | 'large' | 'medium'
  color?: 'black' | 'white' | 'cyan' | 'violet' | 'blue' | 'turquoise'
  viewBox?: string
}

export default function Icon({
  name,
  fontSize,
  color,
  viewBox,
  sx,
}: IconProps) {
  const Svg = IconSet[name]

  if (!Svg) return null

  return (
    <SvgIcon
      sx={[
        (theme) => ({
          '& path': {
            transition: '0.2s',
            fill: color
              ? theme.palette.common[color]
              : theme.palette.common.black,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      fontSize={fontSize}
      component={Svg as React.ElementType<unknown>}
      viewBox={viewBox}
      inheritViewBox={!!!viewBox}
    />
  )
}
