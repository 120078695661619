import React, { ReactElement } from 'react'
import Link from '@components/core/link'
import { styled } from '@mui/material'

import { missingOptionalPropsCheck } from '@system/utils/missingOptionalPropsCheck'
export type MetaNavProps = {
  color?: 'black' | 'white'
  horizontal?: boolean
  navigationItems: Array<{
    contentful_id: string
    titleNavigation?: string
    fields: {
      fullPath: string
    }
  }>
  closeNavigation?: () => void
}

const Root = styled('ul')<{ horizontal: boolean }>(({ horizontal, theme }) => ({
  display: 'flex',
  flexDirection: horizontal ? 'row' : 'column',
  gap: theme.spacing(horizontal ? 6 : 4),
  padding: 0,
  margin: 0,
  listStyle: 'none',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

const NavLink = styled(Link)<{ color: 'black' | 'white' }>(
  ({ theme, color }) => ({
    color: theme.palette.common[color],
    fontSize: '16px',
    fontWeight: 900,
  })
)

export default function Header({
  color,
  horizontal,
  navigationItems,
  closeNavigation,
}: MetaNavProps): ReactElement {
  const defaultColor = color || 'white'

  function onclick() {
    if (closeNavigation) {
      closeNavigation()
    }
  }

  return (
    <Root horizontal={!!horizontal}>
      {navigationItems.map((item, i) => {
        missingOptionalPropsCheck(
          { titleNavigation: item.titleNavigation },
          'Modules | Global | metaNavigation | Header',
          item.contentful_id
        )
        return (
          <li key={i}>
            {item.titleNavigation && (
              <NavLink
                to={item.fields.fullPath}
                onClick={onclick}
                active={
                  typeof window !== 'undefined' &&
                  location &&
                  item.fields.fullPath === location.pathname
                }
                color={defaultColor}
                icon={{
                  name: i === 0 ? 'PartnerLogin' : 'LeichteSprache',
                  color: defaultColor,
                }}
                iconLeft
              >
                {item.titleNavigation}
              </NavLink>
            )}
          </li>
        )
      })}
    </Root>
  )
}
