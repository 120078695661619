import { Components } from '@mui/material'

import FontSize from '../../definitions/fontSize'

export const SvgIconOverrides = (): Components['MuiSvgIcon'] => ({
  styleOverrides: {
    root: {
      verticalAlign: 'middle',
      fontSize: FontSize.get('iconDefault'),
    },
    fontSizeSmall: {
      fontSize: FontSize.get('iconSmall'),
    },
    fontSizeLarge: {
      fontSize: FontSize.get('iconLarge'),
    },
  },
})
