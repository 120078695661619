import React from 'react'
import { Link as MuiLink, useMediaQuery, useTheme } from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'
import IconObject, { IconProps } from '../icon'

export type LinkProps = BASE.Helpers.IReactDefaultProps & {
  id?: string
  to?: string
  download?: boolean
  icon?: IconProps
  iconLeft?: boolean
  isExternal?: boolean
  active?: boolean
  noHover?: boolean
  target?: string
  linkColor?: 'white' | 'black' | 'blue'
  className?: string //comes maybe from other mui inherits
  onClick?: (event: React.SyntheticEvent) => void
  onMouseEnter?: (event: React.SyntheticEvent) => void
  onMouseLeave?: (event: React.SyntheticEvent) => void
  underline?: 'none' | 'hover' | 'always'
}

export default function Link({
  id,
  children,
  className,
  to,
  download,
  isExternal = false,
  active,
  noHover,
  onClick,
  onMouseEnter,
  onMouseLeave,
  icon,
  iconLeft,
  linkColor = 'black',
  target = '_blank',
  sx = null,
  underline = 'none',
}: LinkProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const LinkComponent =
    !to && !onClick
      ? 'div'
      : !to && onClick
      ? 'button'
      : isExternal || download
      ? 'a'
      : GatsbyLink

  const additionalProps =
    !to && onClick
      ? {}
      : isExternal
      ? { href: to, target: target, rel: 'noreferrer' }
      : download
      ? { href: to }
      : { to }

  return (
    <MuiLink
      id={id}
      className={className}
      component={LinkComponent}
      underline={isMobile ? undefined : underline}
      onClick={onClick}
      download={download}
      {...additionalProps}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={[
        (theme) => ({
          display: 'inline-flex',
          alignItems: 'center',
          fontWeight: 700,
          gap: '10px',
          color:
            linkColor === 'black'
              ? theme.palette.common.black
              : linkColor === 'blue'
                ? theme.palette.common.blue
                : theme.palette.common.white,

          textDecoration: 'none',
          backgroundImage:
            underline === 'hover'
              ? 'none'
              : 'linear-gradient(currentColor, currentColor)',
          backgroundPosition: '0% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '0% 1px',
          transition: 'background-size 300ms, opacity 300ms',
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
        active && {
          backgroundSize: '100% 1px',
        },
        (theme) =>
          !noHover && {
            [theme.breakpoints.up('lg')]: {
              '&:hover': {
                backgroundSize: '100% 1px',
              },
            },
          },
      ]}
    >
      {!!icon && iconLeft && (
        <IconObject
          {...icon}
          sx={[
            () => ({
              width: '22px',
            }),
            ...(Array.isArray(icon.sx) ? icon.sx : [icon.sx]),
          ]}
        />
      )}
      {children}
      {!!icon && !iconLeft && (
        <IconObject
          {...icon}
          sx={[
            () => ({
              width: '22px',
            }),
            ...(Array.isArray(icon.sx) ? icon.sx : [icon.sx]),
          ]}
        />
      )}
    </MuiLink>
  )
}
