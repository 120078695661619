import Spacing from '@config/theme/definitions/spacing'
import { Components, Theme } from '@mui/material'

export const ContainerOverrides = (
  theme: Theme
): Components['MuiContainer'] => ({
  variants: [
    {
      props: { maxWidth: 'md' },
      style: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        },
        [theme.breakpoints.up('lg')]: {
          maxWidth: `${Spacing.maxWidth.md}px !important`,
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        },
      },
    },
    {
      props: { maxWidth: 'lg' },
      style: {
        maxWidth: `${Spacing.maxWidth.lg}px !important`,
        [theme.breakpoints.down('lg')]: {
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        },
        [theme.breakpoints.up('lg')]: {
          paddingLeft: '86px',
          paddingRight: '86px',
        },
      },
    },
  ],
})
