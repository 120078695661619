import BoldFont from '@static/font/DIN-Pro-Bold.woff'
import BlackFont from '@static/font/DIN-Pro-Black.woff'
import RegularFont from '@static/font/DIN-Pro-Regular.woff'

export const DINProBlack = {
  fontFamily: 'DINPro Black',
  fontDisplay: 'swap',
  fontWeight: 900,
  src: `url(${BlackFont}) format('woff')`,
}

export const DINProBold = {
  fontFamily: 'DINPro Bold',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${BoldFont}) format('woff')`,
}

export const DINProRegular = {
  fontFamily: 'DINPro Regular',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${RegularFont}) format('woff')`,
}

export const fontFamilyBlack = '"DINPro Black", Arial, Sans-Serif'
export const fontFamilyBold = '"DINPro Bold", Arial, Sans-Serif'
export const fontFamilyRegular = '"DINPro Regular", Arial, Sans-Serif'
