import FontSize from '@config/theme/definitions/fontSize'
import { Components, Theme } from '@mui/material'

export const LinkOverrides = (theme: Theme): Components['MuiLink'] => ({
  styleOverrides: {
    root: {
      color: 'currentcolor',
      fontWeight: 400,
      fontFamily: theme.typography.fontFamilyBold,

      '&.MuiLink-fontSizeSmall': {
        fontSize: FontSize.get('sm'),
      },
      '&.MuiLink-fontSizeMedium': {
        fontSize: FontSize.get('base'),
      },
      '&.MuiLink-fontSizeLarge': {
        fontSize: FontSize.get('lg'),
      },
      '&.MuiLink-fontWeightNormal': {},
      '&.MuiLink-fontWeightBold': {
        fontWeight: 500,
      },
    },
    button: {
      fontFamily: theme.typography.fontFamilyBold,
    },
  },
})
