import { Components, Theme } from '@mui/material'
import { TypeBackground, TypeText } from '@mui/material/styles/createPalette'

import FontSize from '../../definitions/fontSize'

export const ChipOverrides = (theme: Theme): Components['MuiChip'] => ({
  styleOverrides: {
    root: {
      color: (theme.palette.background as TypeBackground).secondary,
      border: `2px solid ${
        (theme.palette.background as TypeBackground).secondary
      }`,
      borderRadius: 0,
      '&.Mui-selected': {
        color: (theme.palette.text as TypeText).inverted,
        backgroundColor: (theme.palette.background as TypeBackground).secondary,
      },
    },
    sizeSmall: {
      fontWeight: 500,
      height: '40px',
      fontSize: FontSize.get('base'),
      padding: `0 ${theme.spacing(4)}`,
      '.MuiSvgIcon-fontSizeMedium': {
        marginLeft: theme.spacing(2),
        fontSize: '12px',
      },
    },
    sizeMedium: {
      fontWeight: 500,
      height: '56px',
      fontSize: FontSize.get('lg'),
      padding: `0 ${theme.spacing(6)}`,
      '.MuiSvgIcon-fontSizeMedium': {
        marginLeft: theme.spacing(4),
        fontSize: '16px',
      },
    },
    label: {
      padding: 0,
    },
  },
})
