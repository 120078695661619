import { TypographyOptions } from '@mui/material/styles/createTypography'
import { Theme } from '@mui/material'
import {
  fontFamilyBlack,
  fontFamilyBold,
  fontFamilyRegular,
} from '../definitions/fontFamily'
import FontSize from '../definitions/fontSize'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    button: React.CSSProperties
    link: React.CSSProperties
    body0: React.CSSProperties
    body3: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    button?: React.CSSProperties
    link?: React.CSSProperties
    body0?: React.CSSProperties
    body3?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  export interface TypographyPropsVariantOverrides {
    button: true
    body0: true
    body3: true
    link: true
  }
}

export const TypographyOverrides = (theme: Theme): TypographyOptions => {
  return {
    ...theme.typography,
    fontFamilyRegular: fontFamilyRegular,
    fontFamilyBold: fontFamilyBold,
    fontFamilyBlack: fontFamilyBlack,
    body0: {
      fontWeight: 400,
      fontFamily: fontFamilyRegular,
      fontSize: FontSize.get('sm'), // 16
      lineHeight: 1.6,
    },
    body1: {
      fontWeight: 400,
      fontFamily: fontFamilyRegular,
      fontSize: FontSize.get('sm'), // 16
      lineHeight: 1.6,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('base'), // 18
      },
    },
    body2: {
      fontWeight: 400,
      fontFamily: fontFamilyRegular,
      fontSize: FontSize.get('base'), // 16
      lineHeight: 1.4,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('lg'), // 18
      },
    },
    body3: {
      fontWeight: 400,
      fontFamily: fontFamilyRegular,
      fontSize: FontSize.get('base'), // 16
      lineHeight: 1.4,
      [theme.breakpoints.up('lg')]: {
        lineHeight: 1.6,
        fontSize: FontSize.get('2lg'), // 20
      },
    },
    h1: {
      fontFamily: fontFamilyBlack,
      fontWeight: 900,
      fontSize: FontSize.get('3xl'), // 48
      lineHeight: 1.2,
      marginBottom: theme.spacing(10),
      hyphens: 'auto',
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('4xl'), // 64
        marginBottom: theme.spacing(8),
      },
    },
    h2: {
      fontFamily: fontFamilyBlack,
      fontWeight: 900,
      fontSize: FontSize.get('2xl'), // 36
      lineHeight: 1.2,
      marginBottom: theme.spacing(6),
      hyphens: 'auto',
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('3xl'), // 48
        marginBottom: theme.spacing(10),
      },
    },
    h3: {
      fontFamily: fontFamilyBlack,
      fontWeight: 900,
      fontSize: FontSize.get('3lg'), // 24
      lineHeight: 1.2,
      marginBottom: theme.spacing(4),
      hyphens: 'auto',
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('2xl'), // 36
        marginBottom: theme.spacing(8),
      },
    },
    h4: {
      fontFamily: fontFamilyBold,
      fontWeight: 700,
      fontSize: FontSize.get('3lg'), // 24
      lineHeight: 1.2,
      marginBottom: theme.spacing(4),
      hyphens: 'auto',
    },
    h5: {
      fontFamily: fontFamilyBold,
      fontSize: FontSize.get('lg'), // 18
      lineHeight: 1.2,
      fontWeight: 700,
      marginBottom: theme.spacing(4),
      hyphens: 'auto',
      [theme.breakpoints.up('lg')]: {
        fontWeight: 900,
        fontSize: FontSize.get('3lg'), // 24
        marginBottom: theme.spacing(6),
      },
    },
    button: {
      textTransform: 'none',
      fontFamily: fontFamilyBold,
      fontWeight: 'bold',
      fontSize: FontSize.get('sm'),
      lineHeight: 1,
    },
    link: {
      fontWeight: 700,
      fontFamily: fontFamilyRegular,
      fontSize: FontSize.get('sm'), // 16
      lineHeight: 1.6,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize.get('base'), // 18
      },
    },
  }
}
