export const missingOptionalPropsCheck = (
  props: object,
  source: string,
  contentful_id: string
) => {
  Object.entries(props).forEach(([key, value]) => {
    if (!value) {
      console.warn(
        `${source} | ${key} is missing in ${contentful_id}`
      )
    }
  })
}
