import React, { ReactElement } from 'react'

import { WrapPageElementNodeArgs, Script, ScriptStrategy } from 'gatsby'

import Layout from '@components/modules/global/layout'

import { ThemeProvider, GlobalStyles } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

import GlobalTextProvider from '@system/providers/GlobalTextProvider'
import LangSwitchProvider from '@system/providers/LangSwitchProvider'
import TrackingProvider from '@system/providers/TrackingProvider'

import theme from '@config/theme'

type PageWrapperProps = WrapPageElementNodeArgs & {
  props: {
    pageContext: BASE.Helpers.IPageContext
    location: Record<string, boolean>
  }
}

export default function PageWrapper({
  element,
  props,
}: PageWrapperProps): ReactElement {
  const darkTheme =
    props.pageContext.page?.darkTheme &&
    props.pageContext.page?.darkTheme === 'true'
      ? true
      : false

  return (
    <React.Fragment>
      <Script
        id="usercentrics-cmp"
        data-settings-id="OUAq5K548J3Kab"
        src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
        data-tcf-enabled
        strategy={ScriptStrategy.idle}
      />
      <Script
        key="usercentrics-sdp"
        type="application/javascript"
        src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"
        strategy={ScriptStrategy.idle}
      />
      <ThemeProvider theme={{ ...theme, darkTheme }}>
        {darkTheme && (
          <GlobalStyles
            styles={{
              body: {
                background: `${theme.palette.common.black} !important`,
                color: `${theme.palette.common.white} !important`,
              },
            }}
          />
        )}
        <TrackingProvider {...props}>
          <CssBaseline />
          <GlobalTextProvider pageContext={props.pageContext}>
            <LangSwitchProvider>
              <Layout pageContext={props.pageContext}>{element}</Layout>
            </LangSwitchProvider>
          </GlobalTextProvider>
        </TrackingProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}
